import React, {useState} from "../../_snowpack/pkg/react.js";
import Preview from "./preview.js";
import UserInfoForm from "./userform.js";
import "./discord.css";
import {CopyButton} from "../components/CopyButton.js";
export default function VocalOverlay() {
  const [users, setUsers] = useState([
    {
      id: "1234",
      username: "SilentUser",
      speaking: false
    },
    {
      id: "5678",
      username: "ChatterBox",
      speaking: true
    }
  ]);
  const [nameDisplay, setNameDisplay] = useState(false);
  const [silentDim, setSilentDim] = useState(true);
  const [speakBump, setSpeakBump] = useState(true);
  const [loopAnimation, setLoopAnimation] = useState(false);
  const [borderRadius, setBorderRadius] = useState(50);
  const [freeCSS, setFreeCSS] = useState("");
  const [animation, setAnimation] = useState("");
  const [defaultAvatar, setDefaultAvatar] = useState("");
  const [defaultAvatarSpeaking, setDefaultAvatarSpeaking] = useState("");
  const [singleLine, setSingleLine] = useState(true);
  const [margin, setMargin] = useState(null);
  const [padding, setPadding] = useState(null);
  const [size, setSize] = useState({
    width: 84,
    height: "auto"
  });
  function newUser() {
    const newuser = {
      id: "",
      username: `New User #${users.length}`,
      speaking: false
    };
    setUsers((users2) => {
      return users2.concat([newuser]);
    });
  }
  function removeUser(index) {
    setUsers(users.filter((_, i) => i !== index));
  }
  function editUser(ev, index) {
    const field = ev.target.name;
    var value = ev.target.value;
    if (ev.target.type === "checkbox") {
      value = ev.target.checked;
    }
    setUsers([
      ...users.slice(0, index),
      {...users[index], [field]: value},
      ...users.slice(index + 1)
    ]);
  }
  function buildRule(selector, rules) {
    if (Object.keys(rules).length == 0) {
      return "";
    }
    var plain_text = selector;
    plain_text += " {\n";
    for (let k in rules) {
      plain_text += `	${k}: ${rules[k]};
`;
    }
    plain_text += "}\n";
    return plain_text;
  }
  function compileStyle() {
    var plain_text = "";
    const voice_state = {};
    const avatar = {};
    const speaking_avatar = {};
    const voice_user = {};
    const name = {};
    if (singleLine) {
      voice_state["height"] = "unset !important";
      voice_state["display"] = "inline-block !important";
      voice_user["text-align"] = "center";
    }
    if (silentDim) {
      avatar["filter"] = "brightness(50%)";
      speaking_avatar["filter"] = "brightness(100%) !important";
      speaking_avatar["border-color"] = "rgba(0, 0, 0, 0) !important";
    }
    if (!nameDisplay) {
      name["display"] = "none";
    }
    if (borderRadius < 50) {
      avatar["border-radius"] = `${borderRadius}% !important`;
    }
    if (size.width === "auto" || size.width > 0) {
      if (!singleLine && size.width > 0) {
        voice_state["min-height"] = `${size.width}px`;
      }
      avatar["width"] = (size.width === "auto" ? "auto" : `${size.width}px`) + " !important";
      avatar["height"] = "auto !important";
    }
    if (speakBump) {
      speaking_avatar["position"] = "relative";
      speaking_avatar["animation-name"] = "speaking-now";
      speaking_avatar["animation-duration"] = "1s";
      speaking_avatar["animation-fill-mode"] = "forwards";
      if (loopAnimation) {
        speaking_avatar["animation-iteration-count"] = "infinite";
      }
      plain_text += "@keyframes speaking-now {\n	0%  { bottom:0px; }\n	15% { bottom:15px; }\n	30% { bottom:0px; }\n}\n";
    }
    if (defaultAvatar) {
      avatar["content"] = `url(${defaultAvatar})`;
    }
    if (defaultAvatarSpeaking) {
      speaking_avatar["content"] = `url(${defaultAvatarSpeaking})`;
    }
    plain_text += buildRule('[class*="Voice_voiceState__"]', voice_state);
    plain_text += buildRule('img[class*="Voice_avatar__"]', avatar);
    plain_text += buildRule('[class*="Voice_avatarSpeaking__"]', speaking_avatar);
    plain_text += buildRule('[class*="Voice_user__"]', voice_user);
    plain_text += buildRule('[class*="Voice_name__"]', name);
    return plain_text.trim();
  }
  function compileCustomAvatar(users2) {
    var plain_text = "";
    for (const usr of users2.filter((v) => v.id !== "")) {
      if (usr.avatar || usr.talk_avatar)
        plain_text += `/* User: ${usr.username} (${usr.id}) */
`;
      if (usr.avatar)
        plain_text += `img[class*="Voice_avatar__"]:([class*="Voice_avatarSpeaking__"])[src*="/${usr.id}/"] {content: url("${usr.avatar}");}
`;
      if (usr.talk_avatar)
        plain_text += `img[class*="Voice_avatar__"][class*="Voice_avatarSpeaking__"][src*="/${usr.id}/"] {content: url("${usr.talk_avatar}") !important;}
`;
      plain_text = plain_text.trimEnd();
      plain_text += "\n\n";
    }
    plain_text += "\n" + freeCSS;
    return plain_text.trim();
  }
  const compiled_css = compileStyle() + "\n\n" + compileCustomAvatar(users);
  return /* @__PURE__ */ React.createElement("div", {
    className: ""
  }, /* @__PURE__ */ React.createElement("h1", null, "Discord Vocal Overlay"), /* @__PURE__ */ React.createElement("div", {
    className: "controls"
  }, /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("input", {
    type: "checkbox",
    id: "display-name",
    onChange: () => setNameDisplay(!nameDisplay),
    defaultChecked: !nameDisplay
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: "display-name"
  }, "Hide usernames")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("input", {
    type: "checkbox",
    id: "silent-dim",
    onChange: () => setSilentDim(!silentDim),
    defaultChecked: silentDim
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: "silent-dim"
  }, "Dim silent users")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("input", {
    type: "checkbox",
    id: "single-line",
    onChange: () => setSingleLine(!singleLine),
    defaultChecked: singleLine
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: "single-line"
  }, "Single line")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "avatar-border-radius"
  }, "Round avatar:"), /* @__PURE__ */ React.createElement("input", {
    type: "number",
    id: "avatar-border-radius",
    min: "0",
    onChange: (ev) => setBorderRadius(Number(ev.target.value)),
    defaultValue: borderRadius
  })), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("input", {
    type: "checkbox",
    id: "speak-bump",
    onChange: () => setSpeakBump(!speakBump),
    defaultChecked: speakBump
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: "speak-bump"
  }, "Speak bump")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("input", {
    type: "checkbox",
    id: "loop-animation",
    disabled: !speakBump,
    onChange: () => setLoopAnimation(!loopAnimation),
    defaultChecked: loopAnimation
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: "loop-animation"
  }, "Loop speaking animation")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "set-width"
  }, "Avatar width:"), /* @__PURE__ */ React.createElement("input", {
    type: "number",
    id: "set-width",
    onChange: (ev) => {
      const value = Number(ev.target.value);
      const sizing = {
        width: isNaN(value) || value <= 0 ? "auto" : value,
        height: size.height
      };
      setSize(sizing);
    },
    defaultValue: size.width || 0
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "free-css"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "free_css"
  }, "Other CSS:"), /* @__PURE__ */ React.createElement("textarea", {
    onChange: (e) => setFreeCSS(e.target.value)
  }))), /* @__PURE__ */ React.createElement("h2", null, "Users"), users.map((usr, index) => {
    return /* @__PURE__ */ React.createElement(UserInfoForm, {
      key: index,
      user: usr,
      index,
      removeUser,
      handleChange: (ev) => {
        editUser(ev, index);
      }
    });
  }), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    onClick: newUser
  }, "+"), /* @__PURE__ */ React.createElement("h2", null, "Preview"), /* @__PURE__ */ React.createElement("p", null, 'Ordered alphabetically just like on Discord, click the user to make them "speak".'), /* @__PURE__ */ React.createElement("div", {
    className: "preview-box"
  }, /* @__PURE__ */ React.createElement(Preview, {
    users,
    style: compiled_css.trim()
  })), /* @__PURE__ */ React.createElement("h2", null, "Code"), /* @__PURE__ */ React.createElement("textarea", {
    className: "copy-paste",
    value: compiled_css.trim(),
    readOnly: true
  }), /* @__PURE__ */ React.createElement(CopyButton, {
    value: compiled_css.trim()
  }));
}
