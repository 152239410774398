import React from "../../_snowpack/pkg/react.js";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "../../_snowpack/pkg/react-router-dom.js";
import "./main.css";
import Home from "../home/index.js";
import VocalOverlay from "../discord/vocal_overlay.js";
function Layout() {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("nav", null, /* @__PURE__ */ React.createElement(Link, {
    to: "/"
  }, "Tools"), /* @__PURE__ */ React.createElement("a", {
    href: "https://blog.senpaisilver.com"
  }, "Blog"), /* @__PURE__ */ React.createElement("a", {
    href: "https://twitter.com/SenpaiSilver"
  }, "Twitter"), /* @__PURE__ */ React.createElement("a", {
    href: "https://youtube.com/SenpaiSilver"
  }, "YouTube"), /* @__PURE__ */ React.createElement("a", {
    href: "https://github.com/SenpaiSilver"
  }, "GitHub")), /* @__PURE__ */ React.createElement("div", {
    className: "outlet"
  }, /* @__PURE__ */ React.createElement(Outlet, null)));
}
function NotFound() {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, "Page not found, sorry about that I guess.");
}
export default function App() {
  return /* @__PURE__ */ React.createElement(BrowserRouter, null, /* @__PURE__ */ React.createElement(Routes, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/",
    element: /* @__PURE__ */ React.createElement(Layout, null)
  }, /* @__PURE__ */ React.createElement(Route, {
    index: true,
    element: /* @__PURE__ */ React.createElement(Home, null)
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "discord/obs-overlay",
    element: /* @__PURE__ */ React.createElement(VocalOverlay, null)
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "*",
    element: /* @__PURE__ */ React.createElement(NotFound, null)
  }))));
}
