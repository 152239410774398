import { c as createCommonjsModule } from '../common/index-ef768b07.js';
import { r as reactDom } from '../common/index-bc63f1fb.js';

var client = createCommonjsModule(function (module, exports) {


{
  exports.createRoot = reactDom.createRoot;
  exports.hydrateRoot = reactDom.hydrateRoot;
}
});

var createRoot = client.createRoot;
export { createRoot };
