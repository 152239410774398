import React from "../_snowpack/pkg/react.js";
import {createRoot} from "../_snowpack/pkg/react-dom/client.js";
import {QueryClient} from "../_snowpack/pkg/react-query.js";
import App from "./app/index.js";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {refetchOnWindowFocus: false, staleTime: Infinity, cacheTime: 0}
  }
});
const container = document.getElementById("app");
if (!container) {
  throw new Error("Failed to find root elements");
}
const root = createRoot(container);
root.render(/* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(App, null)));
