import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import "./vocal_preview.css";
function PreviewSpeaker({user}) {
  const [speaking, setSpeaking] = useState(user.speaking);
  const attr_id = `$${user.id}/=1$${user.id}`;
  const avatar = user.avatar || "/static/discord/avatar_default_silent.png";
  const style_span = {
    color: "rgb(255, 255, 255)",
    fontSize: "14px",
    backgroundColor: "rgba(30, 33, 36, 0.95)"
  };
  useEffect(() => {
    setSpeaking(user.speaking);
  }, [user.speaking]);
  return /* @__PURE__ */ React.createElement("li", {
    className: "Voice_voiceState__OCoZh",
    onClick: () => setSpeaking(!speaking)
  }, /* @__PURE__ */ React.createElement("img", {
    className: `Voice_avatar__htiqH ${speaking ? " Voice_avatarSpeaking__Lali" : ""}`,
    src: `${avatar}#userIDtrick/${user.id}/`,
    alt: ""
  }), /* @__PURE__ */ React.createElement("div", {
    className: "Voice_user__8fGwX"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "Voice_name__TALd9",
    style: style_span
  }, user.username)));
}
export default function Preview({users, style}) {
  const [sortedUsers, setSortedUsers] = useState([]);
  useEffect(() => {
    setSortedUsers([...users].sort((a, b) => a.username.toLocaleLowerCase() < b.username.toLocaleLowerCase() ? -1 : 1));
  }, [users]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("style", null, style), /* @__PURE__ */ React.createElement("div", {
    id: "discord-preview"
  }, /* @__PURE__ */ React.createElement("div", {
    style: {fontFamily: "Whitney, sans-serif, sans", backgroundColor: "transparent"}
  }, /* @__PURE__ */ React.createElement("div", {
    className: "Voice_voiceContainer__adk9M"
  }, /* @__PURE__ */ React.createElement("ul", {
    className: "Voice_voiceStates__a121W"
  }, sortedUsers.map((user, index) => /* @__PURE__ */ React.createElement(PreviewSpeaker, {
    key: index,
    user
  })))))));
}
