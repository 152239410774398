import React from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
export default function Home() {
  const streaming_tools_links = [
    {href: "discord/obs-overlay", title: "Discord OBS Overlay"}
  ];
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", null, "SenpaiSilver.tools"), /* @__PURE__ */ React.createElement("p", null, "Here you'll find some tools that might or might not do stuff.", /* @__PURE__ */ React.createElement("br", null), "All the code and binaries provided are provided as-is, with no warranty whatsoever, use at your own risks."), /* @__PURE__ */ React.createElement("h2", null, "GitHub"), /* @__PURE__ */ React.createElement("p", null, "It's on ", /* @__PURE__ */ React.createElement("a", {
    href: "https://github.com/SenpaiSilver/senpaisilver.tools",
    target: "_blank"
  }, "GitHub"), "."), /* @__PURE__ */ React.createElement("h2", null, "Streaming tools"), /* @__PURE__ */ React.createElement("ul", null, streaming_tools_links.map((e) => {
    return /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement(Link, {
      to: e.href
    }, e.title || e.href));
  })));
}
