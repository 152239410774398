import React from "../../_snowpack/pkg/react.js";
export default function UserInfoForm({user, index, handleChange, removeUser}) {
  return /* @__PURE__ */ React.createElement("form", {
    onChange: (ev) => handleChange(ev, index)
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    onClick: () => removeUser(index)
  }, "-"), /* @__PURE__ */ React.createElement("label", {
    htmlFor: `username-${index}`
  }, "Username: "), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    name: "username",
    id: `username-${index}`,
    defaultValue: user.username
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: `userid-${index}`
  }, "User ID: "), /* @__PURE__ */ React.createElement("input", {
    type: "number",
    name: "id",
    id: `userid-${index}`,
    pattern: "\\d+",
    placeholder: "Discord user ID",
    defaultValue: user.id
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: `avatar-url-${index}`
  }, "Avatar URL: "), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    name: "avatar",
    id: `avatar-url-${index}`,
    defaultValue: user.avatar,
    disabled: !user.id
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: `avatar-talking-url-${index}`
  }, "Avatar Talking URL: "), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    name: "talk_avatar",
    id: `avatar-talking-url-${index}`,
    defaultValue: user.talk_avatar,
    disabled: !user.id
  }));
}
