import React from "../../_snowpack/pkg/react.js";
export function CopyButton({value, text, className}) {
  function copy() {
    const input = document.createElement("textarea");
    input.value = value;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  }
  return /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: className || "",
    onClick: copy
  }, text || "Copy");
}
